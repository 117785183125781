<template>
	<div class="page">
		<Tables ref="xTable" :tableData="List" :totalPage="totalPage" :isLoading="isLoading" :searchFormData="searchFormData" :showRefresh="true" :showSearch="true" :showSeq="false" :showAdd="$hasAccess('market.activitycommon/add')" addName="新增" @getList="getList" @clearSearch="clearSearch" @addItemInTable="addOne">
			<!--搜索自定义内容-->
			<el-form-item slot="search-item">
				<el-input v-model="searchFormData.name" size="small" clearable placeholder="按名称查询">
				</el-input>
			</el-form-item>
			<el-form-item slot="search-item">
				<el-select v-model="searchFormData.cfg_city" size="small" clearable placeholder="按城市查询">
					<el-option label="全部" value="全部"></el-option>
					<el-option v-for="(item,k) in $userArea" :key="k" :label="item" :value="item"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item slot="search-item">
				<el-select v-model="searchFormData.cfg_isvip" size="small" clearable placeholder="按类型查询">
					<el-option label="全部" :value="0"></el-option>
					<el-option label="区分会员" :value="1"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item slot="search-item">
				<el-select v-model="searchFormData.cfg_group_ids" size="small" clearable placeholder="按生效场站查询">
					<el-option v-for="(item,k) in groupList" :key="k" :label="item.group_name" :value="item.group_id"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item slot="search-item">
				<el-select v-model="searchFormData.status" size="small" clearable placeholder="按状态查询">
					<el-option label="开启" :value="1"></el-option>
					<el-option label="关闭" :value="0"></el-option>
				</el-select>
			</el-form-item>
			<!--表格内容-->
			<vxe-table-column slot="table-item" field="id" title="活动ID" align="center" />
			<vxe-table-column slot="table-item" field="name" title="名称" align="center" min-width="100px" />
			<vxe-table-column slot="table-item" field="cfg.city" title="城市" align="center" min-width="100px" />
			<vxe-table-column slot="table-item" field="cfg.platform" title="生效平台" align="center" min-width="30px">
				<template v-slot="{ row }">
					<span>{{$platform[row.cfg.platform||0]}}</span>
				</template>
			</vxe-table-column>
			<vxe-table-column slot="table-item" field="cfg.isvip" title="用户类型" align="center" min-width="100px">
				<template v-slot="{ row }">
					<span>{{ row.cfg.isvip==0?"全部":"区分会员" }}</span>
				</template>
			</vxe-table-column>
			<vxe-table-column slot="table-item" field="cfg.repeatValue" title="循环次数" align="center" min-width="50px">
				<template v-slot="{ row }">
					<span>每{{ (row.cfg.repeatType == 1?"周":"月") }}{{ row.cfg.repeatValue }}次</span>
				</template>
			</vxe-table-column>			
			<vxe-table-column slot="table-item" field="status" title="状态" align="center">
				<template v-slot="{ row }">
					<span>{{row.status==1?'启用':'关闭'}}</span>
				</template>
			</vxe-table-column>
			<vxe-table-column slot="table-item" field="btime" title="有效时间" align="center" min-width="100px">
				<template v-slot="{ row }">
					<span>{{row.btime.substr(0,10)}} - {{row.etime.substr(0,10)}}</span>
				</template>
			</vxe-table-column>
			<vxe-table-column slot="table-item" title="操作" align="center" width="300px">
				<template v-slot="{ row }">
					<el-button size="small" plain @click="editOne(row)" v-if="$hasAccess('market.activitycommon/edit')">编辑</el-button>
					<el-button size="small" plain @click="deleteOne(row)">删除</el-button>
				</template>
			</vxe-table-column>
		</Tables>
		<!-- 编辑 -->
		<el-dialog title="编辑/新增" :visible.sync="editDialogShow" :close-on-click-modal="false" :close-on-press-escape="false" width="500px">
			<el-form :model="editDialogFormData" :rules="rules" ref="editForm" label-width="120px" class="form">
				<el-form-item label="名称" prop="name">
					<el-input v-model="editDialogFormData.name" style="width: 80%;"></el-input>
				</el-form-item>
				<el-form-item label="开始时间" prop="btime">
					<el-date-picker class="from-item-width" unlink-panels v-model="editDialogFormData.btime" type="date" value-format="yyyy-MM-dd" placeholder="开启时间"></el-date-picker>
				</el-form-item>
				<el-form-item label="结束时间" prop="etime">
					<el-date-picker class="from-item-width" unlink-panels v-model="editDialogFormData.etime" type="date" value-format="yyyy-MM-dd" placeholder="结束时间"></el-date-picker>
				</el-form-item>
				<el-form-item label="归属城市" prop="city">
					<el-select v-model="editDialogFormData.cfg.city" placeholder="请选择" style="width:100%" @change="getGroups">
						<el-option label="全部" value="全部"></el-option>
						<el-option v-for="(item,k) in $userArea" :key="k" :label="item" :value="item"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="生效平台" prop="platform">
					<el-select v-model="editDialogFormData.cfg.platform" placeholder="请选择" style="width:100%">
						<el-option v-for="(item,k) in $platform" :key="k" :label="item" :value="parseInt(k)"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="生效场站" prop="group_ids">
					<el-select v-model="editDialogFormData.cfg.group_ids" placeholder="请选择场站" filterable style="width:100%">
						<el-option v-for="(item,k) in groupList" :key="k" :label="item.group_name" :value="item.group_id"></el-option>
					</el-select>
				</el-form-item>				
				<el-form-item label="用户类型" prop="isvip">
					<el-select v-model="editDialogFormData.cfg.isvip">
						<el-option :value="0" label="全部"></el-option>
						<el-option :value="1" label="区分会员"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="优惠券内容" prop="coupons">
					<template v-if="editDialogFormData.cfg.isvip == 0">
						<el-button class="add-button" icon="el-icon-plus" type="primary" size="small" @click="addItem">增加优惠券</el-button><br/>
						<div v-for="(item,idx) in editDialogFormData.cfg.coupons">
							<el-select v-model="editDialogFormData.cfg.coupons[idx][0]" style="width: 130px;" clearable size="small" placeholder="请选择优惠券">
								<el-option v-for="item in couponList" :key="item.coupon_id" :label="item.coupon_name" :value="item.coupon_id">
								</el-option>
							</el-select>
							<el-input-number v-model="editDialogFormData.cfg.coupons[idx][1]" :min="1" :max="100" size="small"></el-input-number>
							<el-button class="del-button" size="small" type="danger" icon="el-icon-delete" @click="delItem(idx)"></el-button> 
						</div>						
					</template>
					<template v-else>
						<br/>VIP用户 <el-button class="add-button" icon="el-icon-plus" type="primary" size="small" @click="addItemVip">增加优惠券</el-button><br/>
						<div v-for="(item,idx) in editDialogFormData.cfg.couponsVip">
							<el-select v-model="editDialogFormData.cfg.couponsVip[idx][0]" style="width: 130px;" clearable size="small" placeholder="请选择优惠券">
								<el-option v-for="item in couponList" :key="item.coupon_id" :label="item.coupon_name" :value="item.coupon_id">
								</el-option>
							</el-select>
							<el-input-number v-model="editDialogFormData.cfg.couponsVip[idx][1]" :min="1" :max="100" size="small"></el-input-number>
							<el-button class="del-button" size="small" type="danger" icon="el-icon-delete" @click="delItemVip(idx)"></el-button> 
						</div>
						非VIP用户 <el-button class="add-button" icon="el-icon-plus" type="primary" size="small" @click="addItemUser">增加优惠券</el-button><br/>
						<div v-for="(item,idx) in editDialogFormData.cfg.couponsUser">
							<el-select v-model="editDialogFormData.cfg.couponsUser[idx][0]" style="width: 130px;" clearable size="small" placeholder="请选择优惠券">
								<el-option v-for="item in couponList" :key="item.coupon_id" :label="item.coupon_name" :value="item.coupon_id">
								</el-option>
							</el-select>
							<el-input-number v-model="editDialogFormData.cfg.couponsUser[idx][1]" :min="1" :max="100" size="small"></el-input-number>
							<el-button class="del-button" size="small" type="danger" icon="el-icon-delete" @click="delItemUser(idx)"></el-button> 
						</div>
					</template>
				</el-form-item>
				<el-form-item label="循环周期" prop="repeatType">
					<el-select v-model="editDialogFormData.cfg.repeatType" placeholder="请选择" style="width:100%">
						<el-option label="每周" :value="1"></el-option>
						<el-option label="每月" :value="2"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="每周期可领次数" prop="repeatValue">
					<el-input-number v-model="editDialogFormData.cfg.repeatValue" :min="0" :max="100" size="small"></el-input-number>
				</el-form-item>
				<el-form-item label="启用关闭" prop="status">
					<el-switch v-model="editDialogFormData.status" :active-value="1" :inactive-value="0" clearable />
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button type="primary" size="small" plain icon="el-icon-check" @click="saveEdit()">保 存
				</el-button>
				<el-button size="small" plain icon="el-icon-close" @click="closeDialog">取 消
				</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script setup>
import Tables from '@/components/tables'
import {ref, onMounted} from 'vue'
import store from '@/store'
import API from '@/service/api.js'
import Common from '@/utils/common.js'
import { MessageBox, Notification , Message} from 'element-ui'

const discountType = ["充电支付","购买会员","购买电卡","购买优惠券"];
const discountKind = ["立减","折扣"];
const discountKindValue = ["元","折"];
const isLoading = ref(false);
const totalPage = ref(0);
const searchFormData = ref({});
const List = ref([]);
const groupList = ref([]); //场站列表
const couponList = ref([]); //优惠券列表
const groupMap = ref({});
const selecttime = ref({});
let rules = {
	name: [{
		required: true,
		message: '请输入名称',
		trigger: 'change'
	}],
	city: [{
		required: true,
		message: '请选择区域',
		trigger: 'change',
		validator: (rule, value, callback)=>{
			if(!("city" in editDialogFormData.value.cfg)) return callback(new Error('请选择区域'));
			callback();
		}
	}],
	group_ids: [{
		required: true,
		message: '请选择场站',
		trigger: 'change',
		validator: (rule, value, callback)=>{
			if((!("group_ids" in editDialogFormData.value.cfg) || editDialogFormData.value.cfg.group_ids.length < 1)) return callback(new Error('请选择场站'));
			callback();
		}
	}],
	btime: [{
		required: true,
		message: '请输入活动开始时间',
		trigger: 'change'
	}],
	etime: [{
		required: true,
		message: '请输入活动结束时间',
		trigger: 'change'
	}]
};

// 编辑
const editDialogShow = ref(false);
const editDialogFormData = ref({btime:"",etime:"",cfg:{group_ids:[]}});

onMounted(()=>{
	//获取电站列表
	getGroups();
	//获取优惠券列表
	const params = {
		token: store.state.user.token,
		page: 1,
		size: 100,
	};
	API.Market.GetCouponList(params, {}).then((res)=>{
		for (let d of res.data) {
			if (d.max_money != null) {
				d.coupon_name = d.coupon_name + '(' + d.max_money + '元)';
			}
		}
		couponList.value = res.data;				
	});	
});
// 表格列表
async function getList(currentPage, pageSize) {
	let params = {
		token: store.state.user.token,
		tp: 9,
		page: currentPage,
		size: pageSize
	}
	params = Object.assign(params, searchFormData.value);
	isLoading.value = true
	const res = await API.Market.ActivityCommon.list(params);
	for(let v of res.data){
		v.cfg = JSON.parse(v.cfg);
		if(!v.cfg.coupons) v.cfg.coupons = [];
		if(!v.cfg.couponsVip) v.cfg.couponsVip = [];
		if(!v.cfg.couponsUser) v.cfg.couponsUser = [];
	} 
	List.value = res.data
	totalPage.value = res.total
	isLoading.value = false
}
function getGroups() { //获取电站列表
	const params = {
		token: store.state.user.token,
		page: 1,
		size: 100,
		city: editDialogFormData.value.cfg.city
	}
	API.Device.GetGroupMinList(params).then((res)=>{
		groupList.value = res;
		for(let v of groupList.value){
			groupMap.value[v.group_id] = v.group_name;
		}
		let gids = [];
		for(let gid of editDialogFormData.value.cfg.group_ids){
			for(let gv of groupList.value){
				if(gv.group_id == gid){
					gids.push(gid);
				}
			}
		}
		editDialogFormData.value.cfg.group_ids = gids;
	});
}
// 重置搜索
function clearSearch() {
	searchFormData.value = {}
}
const editForm = ref(null);
// 重置form
function resetFormData() {
	editDialogFormData.value = {btime:"",etime:"",status:1,cfg:{coupons:[],couponsVip:[],couponsUser:[],group_ids:[],platform:0,city:"全部",isvip:0,repeatType:1,repeatValue:1}}
	if (editForm.value) {
		editForm.value.resetFields()
	}
}
// 新增
function addOne() {
	resetFormData()
	editDialogShow.value = true
}
// 编辑
async function editOne(row) {
	resetFormData();
	//row.status = row.status.toString();
	editDialogFormData.value = Object.assign({}, row);
	delete editDialogFormData.value._XID;
	editDialogShow.value = true
}
const xTable = ref(null);
// 删除
function deleteOne(row) {
	MessageBox.confirm('确定要删除该记录吗', '提示', {
		cancelButtonClass: 'btn-custom-cancel',
		confirmButtonText: '确定',
		cancelButtonText: '取消',
		type: 'warning'
	}).then(async () => {
		await API.Market.ActivityCommon.del({token: store.state.user.token, id: row.id})
		Notification({
			title: '成功',
			message: '删除成功',
			type: 'success'
		})
		xTable.value.refreshTable()
	})
}
// 关闭弹窗
function closeDialog() {
	editDialogShow.value = false
}
// 编辑保存
function saveEdit() {
	editForm.value.validate(async valid => {
		if (valid) {
			let params = {
				token: store.state.user.token,
				form: Object.assign({"tp":9}, this.editDialogFormData)
			}
			params.form.cfg.update_time = Common.DateTimeStr();
			params.form.cfg = JSON.stringify(params.form.cfg);
			params.form = JSON.stringify(params.form);
			if(editDialogFormData.value.id > 0){
				await API.Market.ActivityCommon.edit(params);
			}else{
				await API.Market.ActivityCommon.add(params);
			}
			Notification({
				title: '成功',
				message: '操作成功',
				type: 'success'
			})
			closeDialog()
			xTable.value.refreshTable()
		}
	})
}

function addItem(){ //增加优惠券
	editDialogFormData.value.cfg.coupons.push(['', 1]);
}
function delItem(idx){ //删除优惠券
	editDialogFormData.value.cfg.coupons.splice(idx, 1);
}
function addItemVip(){ //增加优惠券
	editDialogFormData.value.cfg.couponsVip.push(['', 1]);
}
function delItemVip(idx){ //删除优惠券
	editDialogFormData.value.cfg.couponsVip.splice(idx, 1);
}
function addItemUser(){ //增加优惠券
	editDialogFormData.value.cfg.couponsUser.push(['', 1]);
}
function delItemUser(idx){ //删除优惠券
	editDialogFormData.value.cfg.couponsUser.splice(idx, 1);
}
</script>

<style lang="scss" scoped>
</style>
